import React, {useState} from 'react';
import {useParams} from 'react-router-dom';
import {Box, CircularProgress, MenuItem, Modal, Select, TextField} from '@mui/material';
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import MDTypography from "components/MDTypography";
import axios from "axios";
import {getCsrfTokenFromCookies} from "util/getCsrfTokenFromCookies";

const AddActivity = ({onActivityAdded}) => {
    const {clientId} = useParams();
    const [open, setOpen] = useState(false);
    const [activityType, setActivityType] = useState('');
    const [notes, setNotes] = useState('');
    const [status, setStatus] = useState('idle');
    const [errorMessage, setErrorMessage] = useState('');

    const handleOpen = () => setOpen(true);
    const handleClose = () => {
        setOpen(false);
        setActivityType('');
        setNotes('');
        setStatus('idle');
        setErrorMessage('');
    };

    const handleAdd = async () => {
        setStatus('loading');
        try {
            const csrfToken = await getCsrfTokenFromCookies();
            await axios.post(`${process.env.REACT_APP_API_URL}/provider/createActivity`, {
                clientId,
                type: activityType,
                notes,
            }, {
                withCredentials: true,
                headers: {
                    "x-csrf-token": csrfToken,
                },
            });
            setStatus('success');
            if (onActivityAdded) {
                onActivityAdded();
            }
        } catch (error) {
            setStatus('error');
            setErrorMessage(error.response?.data?.message || 'An error occurred. Please try again.');
        }
    };

    return (
        <>
            <MDButton variant="gradient" color="info" size="small" onClick={handleOpen}>
                Add Activity
            </MDButton>
            <Modal
                open={open}
                onClose={handleClose}
                aria-labelledby="add-activity-modal"
                aria-describedby="add-activity-modal-description"
            >
                <Box sx={{
                    position: 'absolute',
                    top: '50%',
                    left: '50%',
                    transform: 'translate(-50%, -50%)',
                    width: 400,
                    bgcolor: 'background.paper',
                    boxShadow: 24,
                    p: 4,
                    borderRadius: 2,
                }}>
                    <MDTypography variant="h6" component="h2" mb={2}>
                        Add New Activity
                    </MDTypography>
                    {status === 'idle' && (
                        <>
                            <Select
                                fullWidth
                                value={activityType}
                                onChange={(e) => setActivityType(e.target.value)}
                                displayEmpty
                                variant="outlined"
                                sx={{mb: 2}}
                            >
                                <MenuItem value="" disabled>Select Activity Type</MenuItem>
                                <MenuItem value="call">Call</MenuItem>
                                <MenuItem value="meeting">Meeting</MenuItem>
                                <MenuItem value="sale">Sale</MenuItem>
                            </Select>
                            <TextField
                                fullWidth
                                multiline
                                rows={4}
                                value={notes}
                                onChange={(e) => setNotes(e.target.value)}
                                placeholder="Add notes here..."
                                variant="outlined"
                                sx={{mb: 2}}
                            />
                            <MDBox display="flex" justifyContent="flex-end" mt={2}>
                                <MDButton color="secondary" onClick={handleClose} sx={{mr: 1}}>
                                    Cancel
                                </MDButton>
                                <MDButton color="info" onClick={handleAdd} disabled={!activityType || !notes.trim()}>
                                    Add Activity
                                </MDButton>
                            </MDBox>
                        </>
                    )}
                    {status === 'loading' && (
                        <MDBox display="flex" justifyContent="center" alignItems="center" height={200}>
                            <CircularProgress/>
                        </MDBox>
                    )}
                    {status === 'success' && (
                        <MDBox textAlign="center">
                            <MDTypography variant="body1" mb={2}>
                                Activity added successfully!
                            </MDTypography>
                            <MDButton color="info" onClick={handleClose}>
                                Close
                            </MDButton>
                        </MDBox>
                    )}
                    {status === 'error' && (
                        <MDBox textAlign="center">
                            <MDTypography variant="body1" color="error" mb={2}>
                                {errorMessage}
                            </MDTypography>
                            <MDButton color="info" onClick={() => setStatus('idle')}>
                                Try Again
                            </MDButton>
                        </MDBox>
                    )}
                </Box>
            </Modal>
        </>
    );
};

export default AddActivity;