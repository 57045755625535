import './sentry'
import {StrictMode} from 'react';
import {createRoot} from 'react-dom/client';
import {createBrowserRouter, RouterProvider} from 'react-router-dom';
import './index.css';
import mainRoutes from 'routes/mainRoutes.js';
import publicRoutes from 'routes/publicRoutes.js';
import BaseLayout from 'routes/BaseLayout.js';


// Import the MaterialUIControllerProvider from your context file
import {MaterialUIControllerProvider} from 'context'; // Adjust the path based on your project structure

const router = createBrowserRouter([
    {
        path: '/',
        element: <BaseLayout/>,
        children: [
            ...publicRoutes,
            ...mainRoutes,
        ],
    },
]);

createRoot(document.getElementById('root')).render(
    <StrictMode>
        <MaterialUIControllerProvider>
            <RouterProvider router={router}/>
        </MaterialUIControllerProvider>
    </StrictMode>
);