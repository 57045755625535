import MDBox from "../../../components/MDBox";
import MDTypography from "../../../components/MDTypography";
import DataTable from "../../../components/DataTable";
import DefaultCell from "../../../components/DataTable/components/DefaultCell";
import {Link, useLoaderData} from "react-router-dom";
import {getCsrfTokenFromCookies} from "../../../util/getCsrfTokenFromCookies";
import axios from "axios";
import Card from "@mui/material/Card";

export const ClientList = () => {
    const loaderData = useLoaderData();
    const createUserRows = (clients) => {
        return clients.map((client) => ({
            systemId: (
                <Link to={`client-details/${client.id}`} style={{textDecoration: 'none', color: 'inherit'}}>
                    <DefaultCell>{client.id}</DefaultCell>
                </Link>
            ),
            firstName: (
                <Link to={`client-details/${client.id}`} style={{textDecoration: 'none', color: 'inherit'}}>
                    <DefaultCell>{client.firstName}</DefaultCell>
                </Link>
            ),
            lastName: (
                <Link to={`client-details/${client.id}`} style={{textDecoration: 'none', color: 'inherit'}}>
                    <DefaultCell>{client.lastName}</DefaultCell>
                </Link>
            ),
        }));
    };

    return (
        <Card sx={{width: "100%", marginTop: 1, marginBottom: 1}}>
            <MDBox pt={2} pb={2}>
                <MDBox display="flex" flexDirection="column" alignItems="center" mt={3} pt={3} px={3}>
                    <MDTypography variant="h6" fontWeight="medium" align="left">
                        My Clients
                    </MDTypography>
                </MDBox>
                <MDBox py={1}>
                    <DataTable
                        table={{
                            columns: [
                                {Header: "System Id", accessor: "systemId"},
                                {Header: "First Name", accessor: "firstName"},
                                {Header: "Last Name", accessor: "lastName"},
                            ],
                            rows: createUserRows(loaderData.clients),
                        }}
                        entriesPerPage={false}
                        showTotalEntries={false}
                        isSorted={false}
                        noEndBorder
                    />
                </MDBox>
            </MDBox>
        </Card>
    )
}

export const clientsLoader = async () => {
    const csrfToken = await getCsrfTokenFromCookies();
    const response = await axios.get(`${process.env.REACT_APP_API_URL}/provider/getClientData`, {
        withCredentials: true,
        headers: {
            "x-csrf-token": csrfToken,
        },
    });
    return response.data;
}