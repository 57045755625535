import React, {useEffect, useState} from 'react';
import {useLoaderData, useRevalidator} from 'react-router-dom';
import axios from 'axios';
import Card from "@mui/material/Card";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDButton from "components/MDButton";
import DataTable from "components/DataTable";
import DefaultCell from "components/DataTable/components/DefaultCell";
import Modal from "@mui/material/Modal";
import {getCsrfTokenFromCookies} from "util/getCsrfTokenFromCookies";

export const ActivityLog = () => {
    const [selectedActivity, setSelectedActivity] = useState(null);
    const [openModal, setOpenModal] = useState(false);
    const [tableData, setTableData] = useState({columns: [], rows: []});
    const activitiesData = useLoaderData();
    const revalidator = useRevalidator();

    useEffect(() => {
        if (Array.isArray(activitiesData)) {
            const columns = [
                {Header: "Date", accessor: "date"},
                {Header: "Type", accessor: "type"},
                {Header: "Notes", accessor: "notes"},
            ];
            const rows = createActivityRows(activitiesData);
            setTableData({columns, rows});
        } else {
            console.error('Unexpected activities data structure:', activitiesData);
        }
    }, [activitiesData]);

    const handleActivityClick = (activity) => {
        setSelectedActivity(activity);
        setOpenModal(true);
    };

    const handleCloseModal = () => {
        setOpenModal(false);
        setSelectedActivity(null);
        revalidator.revalidate();
    };

    const createActivityRows = (activities) => {
        return activities.map((activity) => ({
            date: (
                <DefaultCell>{new Date(activity.createdAt).toLocaleDateString()}</DefaultCell>
            ),
            type: (
                <DefaultCell>{activity.type}</DefaultCell>
            ),
            notes: (
                <DefaultCell>
                    <MDButton variant="text" color="info" onClick={() => handleActivityClick(activity)}>
                        {activity.notes && activity.notes.length > 10
                            ? `${activity.notes.substring(0, 10)}...`
                            : activity.notes || 'No notes'}
                    </MDButton>
                </DefaultCell>
            ),
        }));
    };

    return (
        <MDBox>
            <Card sx={{width: "100%", marginTop: 1, marginBottom: 1}}>
                <MDBox pt={2} pb={2}>
                    <MDBox display="flex" flexDirection="column" alignItems="center" mt={3} pt={3} px={3}>
                        <MDTypography variant="h6" fontWeight="medium" align="left">
                            Activity Log
                        </MDTypography>
                    </MDBox>
                    <MDBox py={1}>
                        {tableData.rows.length > 0 ? (
                            <DataTable
                                table={tableData}
                                entriesPerPage={false}
                                showTotalEntries={false}
                                isSorted={false}
                                noEndBorder
                            />
                        ) : (
                            <MDTypography variant="body2" align="center">
                                No activities to display
                            </MDTypography>
                        )}
                    </MDBox>
                </MDBox>
            </Card>

            <Modal
                open={openModal}
                onClose={handleCloseModal}
                aria-labelledby="activity-modal-title"
                aria-describedby="activity-modal-description"
            >
                <MDBox
                    sx={{
                        position: 'absolute',
                        top: '50%',
                        left: '50%',
                        transform: 'translate(-50%, -50%)',
                        width: 400,
                        bgcolor: 'background.paper',
                        border: '2px solid #000',
                        boxShadow: 24,
                        p: 4,
                    }}
                >
                    {selectedActivity && (
                        <>
                            <MDTypography id="activity-modal-title" variant="h6" component="h2">
                                Activity Details
                            </MDTypography>
                            <MDTypography id="activity-modal-description" sx={{mt: 2}}>
                                Date: {new Date(selectedActivity.createdAt).toLocaleString()}
                            </MDTypography>
                            <MDTypography sx={{mt: 1}}>
                                Type: {selectedActivity.type}
                            </MDTypography>
                            <MDTypography sx={{mt: 1}}>
                                Notes: {selectedActivity.notes || 'No notes'}
                            </MDTypography>
                        </>
                    )}
                    <MDButton onClick={handleCloseModal}>Close</MDButton>
                </MDBox>
            </Modal>
        </MDBox>
    );
};

export const activityLogLoader = async ({params}) => {
    try {
        const csrfToken = await getCsrfTokenFromCookies();
        const response = await axios.get(`${process.env.REACT_APP_API_URL}/provider/getClientActivity/${params.clientId}`, {
            withCredentials: true,
            headers: {
                "x-csrf-token": csrfToken,
            },
        });
        return response.data;
    } catch (error) {
        console.error('Error loading activities:', error);
        throw error;
    }
};